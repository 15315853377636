<div class="table__container">
  <table [class.table--striped]="isStriped" class="table">
    <!--TODO : Add caption / aria description to table-->
    <thead>
      <tr>
        <th
          (click)="onCheckAll()"
          (keydown.enter)="onCheckAll()"
          *ngIf="canCheckRow"
          class="table__check-all"
          tabindex="0"
        >
          <wp-icon *ngIf="!allChecked" icon="square" set="light"></wp-icon>
          <wp-icon *ngIf="allChecked" icon="square-check" set="light"></wp-icon>
        </th>
        <ng-container *ngFor="let column of columns">
          <th>
            <span
              (click)="onSortColumn(column.field)"
              (keydown.enter)="onSortColumn(column.field)"
              [class.table__column--active]="isSorted(column.field)"
              [class.table__column--sortable]="column.sortable"
              [class.table__column--status]="!!column.status"
              class="table__column"
              tabindex="0"
            >
              {{ column.label }}
              <span *ngIf="column.sortable" class="table__sort-icon">
                <wp-icon *ngIf="isSorted(column.field, 'asc')" icon="arrow-down" set="solid"></wp-icon>
                <wp-icon *ngIf="isSorted(column.field, 'desc')" icon="arrow-up" set="solid"></wp-icon>
                <wp-icon *ngIf="column.sortable && !isSorted(column.field)" icon="sort" set="solid"></wp-icon>
              </span>
            </span>
          </th>
        </ng-container>
        <th *ngIf="actions.length > 0">&nbsp;</th>
        <th *ngIf="!!toggleAction">{{ toggleAction.label }}</th>
      </tr>
    </thead>
    <tbody class="table__body">
      <tr *ngIf="data?.length === 0 && !isLoading" class="table__empty">
        <!--        TODO : I18n-->
        <td colspan="100%">No {{ dataName?.toLowerCase() ?? "results" }} found...</td>
      </tr>
      <tr
        (click)="onRowClick(row)"
        *ngFor="let row of data"
        [class.table__row--clickable]="selectRow.observed"
        class="table__row"
      >
        <td
          (click)="onCheckRow($event, row)"
          *ngIf="canCheckRow"
          [class.table__checkbox-cell--checked]="checkedRows.includes(row)"
          class="table__checkbox-cell"
        >
          <!--          // TODO : Make iconset configurable and overwritable-->
          <wp-icon *ngIf="!checkedRows.includes(row)" icon="square" set="light"></wp-icon>
          <wp-icon *ngIf="checkedRows.includes(row)" icon="square-check" set="light"></wp-icon>
        </td>
        <td
          *ngFor="let column of columns"
          [class.table__cell--highlight]="column.highlight"
          [class.table__cell--icon]="column.icon"
          [class.table__cell--status]="!!column.status"
          class="table__cell"
        >
          <!--          TODO : Move icon to standardkit ui-->
          <wp-icon
            *ngIf="column.icon"
            [icon]="column.icon(row)"
            [set]="column.iconSet ? column.iconSet(row) : 'solid'"
          ></wp-icon>
          <!--          TODO : Move pill to standardkit ui-->
          <!--            [icon]="!!column.statusIcon ? column.statusIcon(row) : undefined"-->
          <wp-pill *ngIf="!!column.status && column.status(row)" [type]="column.status(row)">
            <ng-container *ngIf="!column.format">{{ row[column.field] }}</ng-container>
            <ng-container *ngIf="column.format">{{ column.format(row) }}</ng-container>
          </wp-pill>
          <ng-container *ngIf="!column.status && !column.format && !column.icon">{{ row[column.field] }}</ng-container>
          <ng-container *ngIf="!column.status && column.format">{{ column.format(row) }}</ng-container>
        </td>
        <td *ngIf="actions.length > 0" class="table__action-cell">
          <div class="table__action-cell-action">
            <ng-container *ngFor="let action of actions">
              <!--              TODO : Move button to standardkit ui-->
              <wp-button
                (click)="onAction($event, action, row)"
                (keydown.enter)="onAction($event, action, row)"
                *ngIf="!(action.isDisabled && action.isDisabled(row))"
                [attr.set]="action.iconSet"
                [compact]="true"
                [icon]="action.icon"
                [title]="action.label"
                tabindex="0"
                type="transparent"
              >
                {{ action.label }}
              </wp-button>
            </ng-container>
          </div>
        </td>
        <td *ngIf="!!toggleAction" class="table__action-cell">
          <!--          TODO : Implement Toggle-->
          <!--          <ui-toggle-->
          <!--            [isActive]="toggleAction.isActive(row)"-->
          <!--            [isPending]="isPending(index)"-->
          <!--            (toggle)="onToggle(row, index)"-->
          <!--          ></ui-toggle>-->
        </td>
      </tr>
    </tbody>
  </table>
</div>
