import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { LayoutService } from '../layout.service';

@Component({
  selector: 'wp-navigation',
  templateUrl: './navigation.component.html',
  styleUrl: './navigation.component.scss',
})
export class NavigationComponent implements OnInit, OnDestroy {
  public isCollapsed: boolean = false;

  private subscriptions$: Subscription = new Subscription();

  constructor(private layoutService: LayoutService) {}

  public ngOnInit(): void {
    const isCollapsed$ = this.layoutService.isCollapsed$().subscribe((isCollapsed: boolean) => {
      this.isCollapsed = isCollapsed;
    });
    this.subscriptions$.add(isCollapsed$);
  }

  public ngOnDestroy(): void {
    this.subscriptions$.unsubscribe();
  }
}
